@font-face {
    font-family: 'Ubuntu';
    src: url('assets/fonts/Ubuntu/Ubuntu-Regular.ttf');
  }
  @font-face {
    font-family: 'Ubuntu-Regular';
    src: url('assets/fonts/Ubuntu/Ubuntu-Regular.ttf');
  }
  @font-face {
    font-family: 'Ubuntu-Light';
    src: url('assets/fonts/Ubuntu/Ubuntu-Light.ttf');
  }
  @font-face {
    font-family: 'Ubuntu-Bold';
    src: url('assets/fonts/Ubuntu/Ubuntu-Bold.ttf');
  }
  @font-face {
    font-family: 'Ubuntu-Medium';
    src: url('assets/fonts/Ubuntu/Ubuntu-Medium.ttf');
  }